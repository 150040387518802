<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '数据库编辑' : '数据库创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-divider orientation="left" style="margin-top: 0">基本配置</a-divider>
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="数据库类型" prop="databaseType">
        <a-select
          v-model="form.databaseType"
          :disabled="isUpdate"
          :options="options"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="所属操作系统" prop="os">
        <source-select
          v-model="form.os"
          :disabled="isUpdate"
          source-type="os"
        ></source-select>
      </a-form-model-item>
      <a-form-model-item prop="groups">
        <group-select
          v-model="form.groups"
          source-type="database"
        ></group-select>
        <question-icon
          slot="label"
          title="所属群组"
          description="用于分组查询及告警配置"
        ></question-icon>
      </a-form-model-item>
      <a-divider orientation="left">监控配置</a-divider>
      <template v-if="form.databaseType === 'mysql'">
        <a-form-model-item label="DSN">
          <a-input
            v-model="form.macro.dsn"
            :max-length="128"
            placeholder="默认为 `tcp://localhost:3306`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户名">
          <a-input
            v-model="form.macro.user"
            :max-length="128"
            placeholder="默认为 `mysql`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input-password
            v-model="form.macro.password"
            :max-length="128"
            placeholder="默认为 `mysql`"
            :visibility-toggle="!isUpdate"
          ></a-input-password>
        </a-form-model-item>
      </template>
      <template v-else-if="form.databaseType === 'postgresql'">
        <a-form-model-item label="DSN">
          <a-input
            v-model="form.macro.dsn"
            :max-length="128"
            placeholder="默认为 `tcp://localhost:5432`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户名">
          <a-input
            v-model="form.macro.user"
            :max-length="128"
            placeholder="默认为 `postgres`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input-password
            v-model="form.macro.password"
            :max-length="128"
            placeholder="默认为 `postgres`"
            :visibility-toggle="!isUpdate"
          ></a-input-password>
        </a-form-model-item>
      </template>
      <template v-else-if="form.databaseType === 'oracle'">
        <a-form-model-item label="DSN">
          <a-input
            v-model="form.macro.dsn"
            :max-length="128"
            placeholder="默认为 `tcp://localhost:1521`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="Oracle 服务名">
          <a-input
            v-model="form.macro.service"
            :max-length="128"
            placeholder="默认为 `ORA`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户名">
          <a-input
            v-model="form.macro.user"
            :max-length="128"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input-password
            v-model="form.macro.password"
            :max-length="128"
            :visibility-toggle="!isUpdate"
          ></a-input-password>
        </a-form-model-item>
      </template>
      <template v-else-if="form.databaseType === 'mssql'">
        <a-form-model-item label="DSN">
          <a-input
            v-model="form.macro.dsn"
            :max-length="128"
            placeholder="默认为 tcp://localhost:1433"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="实例名称">
          <a-input
            v-model="form.macro.instance"
            :max-length="128"
            placeholder="默认为 `SQLServer`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户名">
          <a-input
            v-model="form.macro.user"
            :max-length="128"
            placeholder="默认为 `mssql`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input-password
            v-model="form.macro.password"
            :max-length="128"
            placeholder="默认为 `mssql`"
            :visibility-toggle="!isUpdate"
          ></a-input-password>
        </a-form-model-item>
      </template>
      <template v-else-if="form.databaseType === 'redis'">
        <a-form-model-item label="DSN">
          <a-input
            v-model="form.macro.dsn"
            :max-length="128"
            placeholder="默认为 `tcp://localhost:6379`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input-password
            v-model="form.macro.password"
            :max-length="128"
            placeholder="默认为空"
            :visibility-toggle="!isUpdate"
          ></a-input-password>
        </a-form-model-item>
      </template>
      <template v-else-if="form.databaseType === 'memcached'">
        <a-form-model-item label="DSN">
          <a-input
            v-model="form.macro.dsn"
            :max-length="128"
            placeholder="默认为 `tcp://localhost:11211`"
          ></a-input>
        </a-form-model-item>
      </template>
      <template v-else-if="form.databaseType === 'mongodb'">
        <a-form-model-item label="DSN">
          <a-input
            v-model="form.macro.dsn"
            :max-length="128"
            placeholder="默认为 `tcp://localhost:27017`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户名">
          <a-input
            v-model="form.macro.user"
            :max-length="128"
            placeholder="默认为 `mongo`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input-password
            v-model="form.macro.password"
            :max-length="128"
            placeholder="默认为 `mongo`"
            :visibility-toggle="!isUpdate"
          ></a-input-password>
        </a-form-model-item>
      </template>
      <template v-else-if="form.databaseType === 'elasticsearch'">
        <a-alert
          message="通过 HTTP 获取 Elasticsearch 监控数据。"
          :show-icon="true"
          style="margin-bottom: 16px"
        ></a-alert>
        <a-form-model-item label="端口">
          <a-input
            v-model="form.macro.port"
            :max="65535"
            :min="1"
            type="number"
            placeholder="默认为 9200"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户名">
          <a-input
            v-model="form.macro.user"
            :max-length="128"
            placeholder="默认为 `es`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input-password
            v-model="form.macro.password"
            :max-length="128"
            placeholder="默认为 `es`"
            :visibility-toggle="!isUpdate"
          ></a-input-password>
        </a-form-model-item>
      </template>
      <template v-else-if="form.databaseType === 'dameng'">
        <a-form-model-item label="DSN">
          <a-input
            v-model="form.macro.dsn"
            :max-length="128"
            placeholder="请输入DSN"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户名">
          <a-input
            v-model="form.macro.user"
            :max-length="128"
            placeholder="请输入用户名"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input-password
            v-model="form.macro.password"
            :max-length="128"
            placeholder="请输入密码"
            :visibility-toggle="!isUpdate"
          ></a-input-password>
        </a-form-model-item>
      </template>
      <template v-else-if="form.databaseType === 'clickhouse'">
        <a-alert
          message="通过 HTTP 获取 ClickHouse 监控数据。"
          :show-icon="true"
          style="margin-bottom: 16px"
        ></a-alert>
        <a-form-model-item label="端口">
          <a-input
            v-model="form.macro.port"
            :max="65535"
            :min="1"
            type="number"
            placeholder="默认为 8123"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户名">
          <a-input
            v-model="form.macro.user"
            :max-length="128"
            placeholder="默认为 `default`"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input-password
            v-model="form.macro.password"
            :max-length="128"
            :visibility-toggle="!isUpdate"
          ></a-input-password>
        </a-form-model-item>
      </template>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading.confirm" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
          <a-button
            v-if="!monitorActiveMode"
            :loading="loading.test"
            type="primary"
            @click="test"
            style="background: #ffa040; border-color: #ffa040"
          >
            测试
          </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  createDatabase,
  getDatabase,
  getDatabaseMonitorMacro,
  updateDatabase,
  testDatabase
} from '@/api/database'
import QuestionIcon from '@/components/icon/QuestionIcon.vue'
import GroupSelect from '@/components/select/GroupSelect.vue'
import SourceSelect from '@/components/select/SourceSelect.vue'
import { databaseTypes } from '@/utils/const'

export default {
  name: 'InputDrawer',
  components: {
    GroupSelect,
    QuestionIcon,
    SourceSelect
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      form: {
        id: '',
        name: '',
        databaseType: 'mysql',
        os: undefined,
        groups: undefined,
        macro: {}
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        databaseType: [
          {
            message: '请选择数据库类型',
            required: true,
            trigger: 'change'
          }
        ],
        os: [
          {
            message: '请选择所属操作系统',
            required: true,
            trigger: 'change'
          }
        ],
        groups: [
          {
            message: '请选择所属群组',
            required: true,
            trigger: 'select'
          }
        ]
      },
      options: databaseTypes.map(value => {
        return {
          title: this.$t(`database_type.${value}`),
          value
        }
      }),
      loading: {
        confirm: false,
        test: false
      }
    }
  },
  computed: {
    ...mapGetters(['monitorActiveMode'])
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        getDatabase(id)
          .then(res => {
            const data = res.data
            this.form.id = data.id
            this.form.name = data.name
            this.form.databaseType = data.database_type
            this.form.os = data.os
            this.form.groups = data.groups
          })
          .finally(() => {
            this.visible = true
          })
        getDatabaseMonitorMacro(id).then(res => {
          this.form.macro = res.data
        })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        databaseType: 'mysql',
        os: undefined,
        groups: undefined,
        macro: {}
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading.confirm = true
          const params = {
            name: this.form.name
          }
          if (this.form.groups) {
            params.group_ids = this.form.groups.map(group => group.id)
          }
          if (Object.keys(this.form.macro).length !== 0) {
            params.macro = this.form.macro
          }
          if (this.isUpdate) {
            updateDatabase(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              }).finally(() => {
                this.loading.confirm = false
              })
          } else {
            params.database_type = this.form.databaseType
            params.os_id = this.form.os.id
            createDatabase(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              }).finally(() => {
                this.loading.confirm = false
              })
          }
        }
      })
    },
    test () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            os_id: this.form.os.id,
            macro: this.form.macro,
            database_type: this.form.databaseType
          }
          this.loading.test = true
          testDatabase(params)
            .then(res => {
              this.$message.success(res.message)
            })
            .finally(() => {
              this.loading.test = false
            })
        }
      })
    }
  },
  watch: {
    'form.databaseType' () {
      this.form.macro = {}
    }
  }
}
</script>
